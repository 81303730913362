<template>
  <div class='indexbox'>
    <div class="searchbox">
      <div class="tapboxs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item><a href="formList">Form Head List</a></el-breadcrumb-item>
          <el-breadcrumb-item>Import</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="tablebox">
      <div class="bodybox" v-loading="loading">
        <div class="topbox">
          <div class="left">
            <div class="left">
              <p>Step 1</p>
              <p>Copy Your Inventory List Into The Template</p>
              <p>Export Your Inventory List From Your Old System As Excel File. Using Excel Or Another Spreadsheet Editor,
                Copy And Paste Your Inventory List From The Exported File Into The Downloaded Template.</p>
            </div>
          </div>
          <el-button class="download" @click="downloaddata" :loading='btn_loading'>Download Excel Template</el-button>
        </div>
        <div class="listbox">
          <el-collapse v-model="activeNames">
            <el-collapse-item title="Required Fields" name="1">
              <div class="titbox">
                <!-- <p>Required Fields</p> -->
                <p>Each Product Is Identified By A Unique ProductCode.</p>
              </div>
              <div class="tabbox">
                <p>FIELD NAME</p>
                <p>WHAT IS IT?</p>
              </div>
              <div class="tablist" v-for="val in data" :key="val.id">
                <p>{{ val.title }}</p>
                <p>{{ val.remark }}({{ val.is_required == 1 ? 'Required' : '' }}{{ val.is_date == 1 ? `、${val.format}` :
                  '' }})</p>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="bodybox" style="border:none;">
        <div class="topbox">
          <div class="left">
            <div class="left">
              <p>Step 2</p>
              <p>Import The Updated Template File</p>
              <p>The File You Import Must Be An Excel File. The Name Of Your File Should End With .xls .xlsx .csv</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bodybox" v-if="show" style="border-bottom:none;">
        <div class="draggable">
          <el-table stripe :data="tableData" height="500">
            <el-table-column type="index" width="80"></el-table-column>
            <el-table-column v-for="(val, index) in header" :key='index' :prop="val" :label="val" min-width='160'
              align='center' show-overflow-tooltip />
          </el-table>
        </div>
      </div>
      <div class="bodybox" style="border-bottom:none;padding-top: 0;">
        <div class="downloadbox" v-if="json_url">
          <a @click="downloadJSON" :download="json_url" target="_blank">Click here to download the file!</a>
        </div>
        <div class="upfile" v-hasPermi="['jac']">
          <el-form ref="form" :model="form" :rules="rules" class="formbox">
            <el-form-item prop="file">
              <el-upload :limit='1' class="upload-demo" action='' drag :auto-upload="false" accept=".xlsx, .xls, .csv"
                :on-change="Uploadchange" :on-remove='clearFiles' :file-list="fileList">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Drag The File Here, Or<em>Click Upload</em></div>
                <div style="color:red" slot="tip">Tip: Only Import Is Allowed “xls”,“csv” or “xlsx” Format File！</div>
              </el-upload>
            </el-form-item>
            <el-form-item class="btnbox">
              <el-button type="primary" @click.native.prevent="loadOrders" :loading='btn_loading' v-if="!show">Verify
                File</el-button>
              <div>
                <el-button type="primary" @click.native.prevent="clearFiles" v-if="btn_loading || show">Clean Verify
                  File</el-button>
                <div style="height: 30px;"></div>
              </div>
              <el-button class="download" type="primary" :loading='loadinga' v-if="show"
                @click="importOrders">Import</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getToken } from '@/utils/auth'
import { mapGetters } from 'vuex';
import FileSaver from "file-saver";
export default {
  data() {
    return {
      activeNames: [],
      fileList: [],
      form: { file: '', },
      formName: '',
      rules: {
        file: [
          { required: true, trigger: "blur", message: 'Please Upload The File' }
        ]
      },
      data: [],
      loading: false,

      show: false,
      header: [],
      tableData: [],
      id: '',
      json_url: '',

      btn_loading: false,
      btn_loadinga: false,
      loadinga: false,
    };
  },
  computed: {
    ...mapGetters(['api_id']),
  },
  created() {
    this.getdata();
  },
  methods: {
    getdata() {
      this.loading = true
      this.$post({
        url: "/app/header_field/getList",
        data: {
          api_id: this.api_id
        },
        success: (res) => {
          this.data = res.data
          setTimeout(() => {
            this.loading = false
          }, 200);
        },
        tip: () => {
          this.loading = false
        },
      })
    },
    Uploadchange(file) {
      this.formName = file.name.match(/[^\\\/]+(?=\.[^\\\/.]+$)/)[0];
      this.form.file = file.raw
      this.header = ''
      this.tableData = ''
      this.id = ''
      this.show = false
    },
    clearFiles() {
      this.fileList = []
      this.tableData = []
      this.form.file = ''
      this.formName = ''
      this.header = ''
      this.tableData = ''
      this.id = ''
      this.show = false
    },
    loadOrders() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.btn_loading = true
          let data = new FormData();
          data.append("file", this.form.file);
          data.append("api_id", this.api_id);
          data.append("token", getToken());
          this.$post({
            url: "/app/import_jira/getImportData",
            upload: true,
            data,
            success: (res) => {
              this.$message({ message: 'Finished', type: 'success' });
              this.header = res.data.header
              this.tableData = res.data.list
              this.id = res.data.id
              setTimeout(() => {
                this.show = true
                this.btn_loading = false
                window.scrollTo(0, document.documentElement.scrollHeight)
              }, 1500);
            },
            tips: (err) => {
              this.btn_loading = false
              this.$notify({ title: 'Verification failed', message: err.msg, type: 'error', duration: 0 });
              this.clearFiles()
            },
          })
        }
      });
    },
    //导入模板下载
    downloaddata() {
      const baseURL = process.env.VUE_APP_BASE_API
      let url = baseURL + '/app/header_field/exportHeader?api_id=' + this.api_id + '&token=' + getToken()
      window.open(url)
      this.btn_loading = true
      setTimeout(() => {
        this.btn_loading = false
      }, 1200);
      // this.$post({
      //   url: "/app/header_field/exportHeader",
      //   success: (res) => {
      //     let url = res.data
      //     window.open(url)
      //     setTimeout(() => {
      //       this.btn_loading = false
      //     }, 1200);
      //   },
      //   tip: () => {
      //     setTimeout(() => {
      //       this.btn_loading = false
      //     }, 1200);
      //   },
      // })
    },
    importOrders() {
      this.loadinga = true
      this.$post({
        url: "/app/import_jira/importDo",
        data: { id: this.id, api_id: this.api_id },
        success: (res) => {
          this.$message({ message: 'Success', type: 'success' });
          this.json_url = JSON.stringify(res.data.data)
          this.form = { file: '' }
          this.fileList = []
          this.show = false
          this.header = []
          this.tableData = []
          this.id = ''
          this.loadinga = false
          this.$alert('The file import was successful. Do you want to download it?', 'Tips', {
            confirmButtonText: 'download',
            callback: action => {
              if (action == 'confirm') {
                this.downloadJSON()
              }
            }
          });
        },
        tip: () => {
          this.loadinga = false
        },
      })
    },
    downloadJSON() {
      let data = this.json_url
      let blob = new Blob([data], { type: "application/json" });
      FileSaver.saveAs(blob, `${this.formName} Converted.json`);
    }
  },
}
</script>
<style lang='scss' scoped>
.indexbox {
  width: 100%;
  height: 100%;
  .searchbox {
    width: 100%;
    padding: 10px 30px 0;
    .tapboxs {
      width: 100%;
      padding-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    /deep/.el-tabs__header {
      margin-bottom: 0;
      .el-tabs__nav-wrap::after {
        display: none;
      }
    }
    background: #fff;
    border-bottom: 1px solid #babdcd;
  }
  .tablebox {
    width: 100%;
    padding: 20px;
    .bodybox {
      width: 100%;
      padding: 20px;
      background: #fff;
      border-bottom: 1px solid #dbdbdb;
      .topbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #999999;
        font-size: 14px;
        p:nth-child(1) {
          color: #333333;
          font-weight: 550;
        }
        p:nth-child(2) {
          color: #666666;
          line-height: 2;
        }
      }
      /deep/.el-button--default {
        color: #19cbbf;
        background-color: #fff;
        border-color: #19cbbf;
        border-radius: 4px;
      }
      .listbox {
        width: 100%;
        margin-top: 15px;
        padding: 10px 15px;
        background: #f1f6fb;
        border-radius: 10px;
        /deep/.el-collapse-item__header {
          border-bottom: none;
        }
        .titbox {
          width: 100%;
          color: #999999;
          font-size: 14px;
          p:nth-child(1) {
            color: #333333;
            font-weight: 550;
          }
          p:nth-child(2) {
            padding: 10px 0 20px;
          }
        }
        .tabbox {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: #333333;
          font-size: 14px;
          font-weight: 550;
          padding: 8px 0;
          border-bottom: 1px solid #dbdbdb;
          p:nth-child(1) {
            width: 20%;
          }
        }
        .tablist {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          font-weight: 550;
          padding: 8px 0;
          line-height: 1.6;
          p:nth-child(1) {
            width: 20%;
            color: #333333;
            font-size: 14px;
          }
          p:nth-child(2) {
            width: 80%;
            color: #666666;
            font-size: 12px;
          }
        }
        /deep/.el-collapse {
          border: none;
          .el-collapse-item__header {
            background: none;
            color: #333333;
            font-size: 14px;
            font-weight: 550;
          }
          .el-collapse-item__content {
            padding-bottom: 0;
          }
          .el-collapse-item__wrap {
            border-bottom: none;
            background: none;
            .tabtit {
              margin-bottom: 20px;
              color: #999999;
              font-size: 14px;
            }
          }
        }
      }
    }
    .bodybox:nth-child(1) {
      border-radius: 10px 10px 0 0;
    }
    .bodybox:last-child {
      border-bottom: none;
      border-radius: 0 0 10px 10px;
      .upfile {
        padding: 10px 0 0;
      }
      /deep/.el-upload-dragger {
        width: 100%;
      }
    }
    .downloadbox {
      width: 100%;
      padding: 0 0 10px 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      >a {
        background: $color;
        padding: 5px 30px 6px;
        border: 1px solid $color;
        border-radius: 10px;
        color: #000;
        font-size: 14px;
        font-weight: 550;
        cursor: pointer;
      }
    }
  }
  .formbox {
    /deep/.el-form-item {
      margin-bottom: 30px !important;
      .el-form-item__content {
        width: 100%;
        .el-button {
          width: 100%;
          height: 48px;
          background: $color;
          border-radius: 5px;
          border-color: $color;
        }
      }
    }
  }
}
</style>
